import { createApp } from "vue";
import { createMetaManager } from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; // Theme
import "primevue/resources/primevue.min.css"; // Core CSS
import "primeicons/primeicons.css"; // Icons

// Import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Import Bootstrap JavaScript
import "bootstrap";

// Import Swiper CSS
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Button from "primevue/button";
import Menubar from "primevue/menubar";
import Carousel from "primevue/carousel";
import InputText from "primevue/inputtext";

import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
import "swiper/css/autoplay";

// Import Font Awesome core
import { library } from "@fortawesome/fontawesome-svg-core";

// Import Font Awesome icon component
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Import specific icons
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

// Add icons to the library
library.add(faFacebookF, faTwitter, faInstagram, faLinkedinIn);
library.add(fas);
library.add(fab);

// Install Swiper modules
// SwiperCore.use([Pagination, Navigation, Autoplay]);

const metaManager = createMetaManager();
// Create Vue app
createApp(App)
  .use(store)
  .use(metaManager)
  .use(router)
  .use(PrimeVue)
  .use(VueReCaptcha, {
    siteKey: "6LcegSIqAAAAAC-r5Jk4zbabL01ut1f3wH5sjmd8",
  })
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("Button", Button)
  .component("Menubar", Menubar)
  .component("Carousel", Carousel)
  .component("InputText", InputText)
  .component("Swiper", Swiper)
  .component("SwiperSlide", SwiperSlide)
  .mount("#app");
