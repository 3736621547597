<template>
  <!-- <link rel="preload" as="image" href="@/assets/home-hero.webp" /> -->
  <div class="home-hero">
    <div class="container-fluid container-lg">
      <div class="row">
        <div class="col-12 col-lg-6 col-xxl-7 hero-txt-area">
          <span class="welcome-txt">WELCOME TO DEVOLYSIS</span>
          <h1 class="heading-txt">Your Web Design Partner for Success</h1>
          <p class="para-txt">
            We design and build beautiful websites and branding for leading tech
            and startup businesses.
          </p>
          <router-link class="button quote-btn" to="/quote">
            Request a Quote ->
          </router-link>
          <p class="design-txt">
            Design you'll 💙, <span class="gur-txt"> guaranteed!</span>
          </p>

          <div class="row">
            <div class="col-sm-6 col-xl-7 col-xxl-8"></div>
            <div class="col-12 col-sm-6 col-xl-5 col-xxl-4">
              <div class="projects-done text-center">
                <div class="row">
                  <div class="col-3">
                    <img :src="ProjectDoneSvg" alt="ProjectDoneSvg" />
                  </div>

                  <div class="col-9">
                    <span class="project-num">500+</span> <br />
                    <span class="project-title">Projects Done</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xxl-5 hero-img-area img-fluid">
          <img
            class="img-fluid"
            src="@/assets/home-hero.webp"
            importance="high"
            alt="Home"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectDoneSvg from "@/assets/projects-done.svg";

export default {
  name: "HomeHero",
  data() {
    return {
      ProjectDoneSvg: ProjectDoneSvg,
    };
  },
};
</script>

<style scoped>
.home-hero {
  padding-top: 100px;
}
.hero-txt-area {
  margin-top: 50px;
  text-align: left;
}

.hero-img-area {
  background-color: #2aaeff;
}

.welcome-txt {
  font-size: 18px;
  font-weight: 600;
}

.heading-txt {
  margin-top: 20px;
  font-size: 65px;
  font-weight: 700;
}

.para-txt {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}

.quote-btn {
  display: inline-block; /* Ensure it's treated like an inline element */
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  padding: 10px 50px;
  border: none;
  color: var(--text-color);
  background-color: var(--primary-color);
  text-decoration: none;
  text-shadow: 0.1px 0.1px 0.1px #000000;
}

.quote-btn:hover {
  transform: scale(0.85);
  background-color: #224486;
}

.design-txt {
  padding-top: 50px;
  font-size: 15px;
  font-weight: 500;
}

.gur-txt {
  font-weight: 800;
}

.projects-done {
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px 20px;
}

.project-num {
  font-size: 28px;
  font-weight: 700;
  color: #224486;
}

.project-title {
  font-size: 16px;
  font-weight: 400;
  color: black;
}

@media only screen and (max-width: 1399px) {
  .heading-txt {
    font-size: 55px;
  }
}

@media only screen and (max-width: 1199px) {
  .heading-txt {
    font-size: 45px;
  }

  .para-txt {
    font-size: 18px;
  }

  .projects-done {
    padding: 12px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .home-hero {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .heading-txt {
    font-size: 35px;
  }
}
</style>
