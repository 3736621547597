<template>
  <div class="happy-customers">
    <div class="container">
      <div class="row">
        <div class="col-4 text-end"><img :src="Happy1" alt="Happy" /></div>
        <div
          class="col-4 text-center d-flex align-items-center justify-content-center full-height"
        >
          <h2>Happy Customers</h2>
        </div>
        <div class="col-4 text-start"><img :src="Happy2" alt="Happy" /></div>
      </div>
      <div class="row swiper-row">
        <swiper
          :slides-per-view="3"
          :space-between="30"
          ref="swiper"
          pagination
          grab-cursor
          class="mySwiper"
          :autoplay="{ delay: 5000 }"
          swiper.autoplay.running="true;"
        >
          <swiper-slide
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <div class="card">
              <div class="card-body">
                <h2>{{ testimonial.client }}</h2>
                <hr />
                <p>{{ testimonial.feedback }}</p>
                <hr />
                <img
                  :src="testimonial.logo"
                  alt=""
                  width="150px"
                  height="50px"
                />
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Happy1 from "@/assets/happy1.svg";
import Happy2 from "@/assets/happy2.svg";

export default {
  name: "HappyCustomers",
  data() {
    return {
      Happy1: Happy1,
      Happy2: Happy2,
      testimonials: [
        {
          client: "Muhammad Saeed",
          feedback:
            "Devolysis consistently exceeds expectations, delivering exceptional results on time. Their final website is highly professional.",
          logo: require("../assets/logo-car6.webp"),
        },
        {
          client: "S Amini",
          feedback:
            "They have maintained excellent communication and offered top-notch expertise, earning a perfect 10/10 rating!",
          logo: require("../assets/logo-car5.webp"),
        },
        {
          client: "Ara Khan",
          feedback:
            "Devolysis led by Abdullah provide courteous, competent, and readily available services. Highly Recommended.",
          logo: require("../assets/logo-car1.webp"),
        },
        {
          client: "George Trakl",
          feedback:
            "Devolysis Did An Excellent Job On Our Landing Page And Gave Us Exactly What We Needed In A Very Timely Manner. Made Good Revisions Were Needed And Were Patient With Us. Excellent Professional. 10/10",
          logo: require("../assets/logo-car3.webp"),
        },
        {
          client: "Fergus Lowrey",
          feedback:
            "The developers are highly professional, understanding user needs, and consistently exceeding expectations. Best Experience.",
          logo: require("../assets/logo-car2.webp"),
        },
        {
          client: "Mubashir Nawaz",
          feedback:
            "Developed a fully functional responsive website, surpassing expectations in record time.",
          logo: require("../assets/logo-car8.webp"),
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style scoped>
.happy-customers {
  margin-top: 150px;
  margin-bottom: 100px;
}
.happy-customers h2 {
  font-size: 35px;
  font-weight: 700;
}

.mySwiper {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 40px;
}
.card {
  margin: 10px;
  min-height: 400px;
}

.swiper-row h2 {
  font-size: 24px;
  font-weight: 600;
  padding: 20px 0;
}

.swiper-row p {
  font-size: 18px;
  font-weight: 400;
  padding: 20px 0;
}
</style>
