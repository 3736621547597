<template>
  <div class="call-action">
    <div class="container-fluid container-lg black-area">
      <div class="row">
        <div class="col-12">
          <p class="ready-txt">Ready to get started?</p>
          <p class="imagine-txt">Reimagine your business solutions today</p>
        </div>
      </div>
      <div class="row quote-row">
        <div class="col-12">
          <router-link class="button quote-btn" to="/quote">
            Get Started ~ it's free ->
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallAction",
};
</script>

<style scoped>
.call-action {
  margin-top: 200px;
  margin-bottom: 100px;
}

.black-area {
  background-color: rgb(0, 0, 0);
  padding: 100px;
  border-radius: 20px;
}

.ready-txt {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.imagine-txt {
  font-size: 40px;
  font-weight: 600;
  color: white;
  margin-top: 30px;
}

.quote-row {
  margin-top: 30px;
}

.quote-btn {
  display: inline-block; /* Ensure it's treated like an inline element */
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  padding: 10px 50px;
  border: none;
  color: black;
  background-color: white;
  text-decoration: none;
}

.quote-btn:hover {
  transform: scale(0.85);
  background-color: white;
}

@media only screen and (max-width: 991px) {
  .call-action {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .call-action {
    padding-right: 20px;
    padding-left: 20px;
  }

  .imagine-txt {
    font-size: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .black-area {
    padding: 50px;
  }

  .imagine-txt {
    font-size: 20px;
  }
}
</style>
