<template>
  <footer class="footer-menu">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="design-txt text-start">
            Design you'll 💙, <span class="gur-txt"> guaranteed!</span>
          </p>
        </div>
      </div>
      <div class="row row2 text-start">
        <div class="col-12 col-lg-4">
          <img
            src="../assets/logo.webp"
            alt="logo"
            width="200px"
            height="60px"
          />
          <p class="">
            We design and build beautiful websites, apps and branding for
            leading tech and startup businesses.
          </p>
        </div>
        <div class="col-6 col-lg-4">
          <h3>Services</h3>
          <ul>
            <li>
              <router-link class="footer-links" to="/website-design"
                >-> Website Design</router-link
              >
            </li>
            <li>
              <router-link class="footer-links" to="/e-commerce"
                >-> E-commerce</router-link
              >
            </li>
            <li>
              <router-link class="footer-links" to="/logo-design"
                >-> Logo Design</router-link
              >
            </li>
            <li>
              <router-link class="footer-links" to="/content-writing"
                >-> Content Writing</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-lg-4">
          <h3>Company</h3>
          <ul>
            <li>
              <router-link class="footer-links" to="/about"
                >-> About</router-link
              >
            </li>
            <li>
              <router-link class="footer-links" to="/our-company"
                >-> Our Company</router-link
              >
            </li>
            <li>
              <router-link class="footer-links" to="/our-team"
                >-> Our Team</router-link
              >
            </li>
            <li>
              <router-link class="footer-links" to="/contact"
                >-> Contact</router-link
              >
            </li>
            <li>
              <router-link class="footer-links" to="/faqs">-> FAQ</router-link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-6 text-sm-start">
          <span class="copyright">
            © 2023 Devolysis LLC. All rights reserved
          </span>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 text-sm-end">
          <router-link to="/privacy-policy" class="policy-link"
            >Privacy Policy
          </router-link>
          <router-link to="/terms-and-conditions" class="policy-link">
            Terms & Conditions</router-link
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterMenu",
};
</script>

<style scoped>
.footer-menu {
  padding-bottom: 50px;
  background-color: var(--tertiary-color);
}

.row2 {
  margin-top: 50px;
}

.row2 p {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
}

.design-txt {
  padding-top: 50px;
  font-size: 15px;
  font-weight: 500;
}

.gur-txt {
  font-weight: 800;
}

.copyright {
  font-size: 13px;
  font-weight: 400;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.footer-links {
  text-decoration: none;
  color: black;
}

.footer-links:hover {
  color: var(--primary-color);
}

.policy-link {
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  color: black;
  margin-right: 20px;
}

.policy-link:hover {
  color: var(--primary-color);
}
</style>
