<template>
  <div class="mainmenu">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid container-lg">
        <router-link to="/">
          <img
            class="img-fluid"
            width="200px"
            src="../assets/logo.webp"
            alt="LOGO"
          />
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link
                id="about-link"
                class="nav-link"
                activeClass="blue-color"
                aria-current="page"
                to="/about"
              >
                ABOUT
              </router-link>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="serviceDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                SERVICES
              </a>
              <ul class="dropdown-menu" aria-labelledby="serviceDropdown">
                <li>
                  <router-link
                    id="website-link"
                    class="dropdown-item"
                    activeClass="black-color"
                    to="/website-design"
                  >
                    WEBSITE DESIGN
                  </router-link>
                </li>
                <li>
                  <router-link
                    id="ecommerce-link"
                    class="dropdown-item"
                    activeClass="black-color"
                    to="/e-commerce"
                  >
                    E-COMMERCE
                  </router-link>
                </li>
                <li>
                  <router-link
                    id="logo-link"
                    class="dropdown-item"
                    activeClass="black-color"
                    to="/logo-design"
                  >
                    LOGO DESIGN
                  </router-link>
                </li>
                <li>
                  <router-link
                    id="content-link"
                    class="dropdown-item"
                    activeClass="black-color"
                    to="/content-writing"
                  >
                    CONTENT WRITING
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                activeClass="blue-color"
                href="#"
                id="whyDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                WHY US
              </a>
              <ul class="dropdown-menu" aria-labelledby="whyDropdown">
                <li>
                  <router-link
                    id="company-link"
                    class="dropdown-item"
                    activeClass="black-color"
                    to="/our-company"
                  >
                    OUR COMPANY
                  </router-link>
                </li>
                <li>
                  <router-link
                    id="team-link"
                    class="dropdown-item"
                    activeClass="black-color"
                    to="/our-team"
                  >
                    OUR TEAM
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link
                id="portfolio-link"
                class="nav-link"
                activeClass="blue-color"
                aria-current="page"
                to="/portfolio"
              >
                PORTFOLIO
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                id="contact-link"
                class="nav-link"
                activeClass="blue-color"
                aria-current="page"
                to="/contact"
              >
                CONTACT
              </router-link>
            </li>
          </ul>
          <router-link class="button quote-btn" to="/quote">
            Request a Quote ->
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped>
.navbar {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.nav-item,
.quote-btn {
  margin-left: 50px;
  transition: transform 0.4s ease-in-out;
}

.quote-btn {
  padding: 10px 20px;
  border: none;
  color: var(--text-color);
  background-color: var(--primary-color);
  text-decoration: none;
  text-shadow: 0.1px 0.1px 0.1px #000000;
}

.nav-item:hover,
.quote-btn:hover {
  transform: scale(0.85);
}

.quote-btn:hover {
  background-color: var(--secondary-color);
}

.dropdown-menu {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.dropdown-item {
  color: white;
  padding: 10px 50px;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.nav-link:hover {
  color: var(--primary-color);
}

.blue-color {
  color: var(--primary-color);
}

.black-color {
  color: black;
  background-color: white;
}

a {
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 1399px) {
  a {
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 1199px) {
  .nav-item,
  .quote-btn {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  #navbarNav {
    height: 250px;
  }
}
</style>
