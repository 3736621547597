<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --primary-color: #2aaeff;
  --secondary-color: #224486;
  --tertiary-color: #fafafa;
  --text-color: white;
}

#app {
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1f2b38;
  font-weight: 600;
}
</style>
