<template>
  <div class="logo-carousel">
    <div class="slider">
      <div class="slide-track">
        <div class="slide" v-for="(slide, index) in slides" :key="index">
          <img
            :src="slide.src"
            :height="slide.height"
            :width="slide.width"
            :alt="slide.alt"
          />
        </div>
        <div
          class="slide"
          v-for="(slide, index) in slides"
          :key="'duplicate-' + index"
        >
          <img
            :src="slide.src"
            :height="slide.height"
            :width="slide.width"
            :alt="slide.alt"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoCarousel",
  data() {
    return {
      slides: [
        {
          src: require("../../assets/logo-car1.webp"),
          height: "40px",
          width: "120px",
          alt: "",
        },
        {
          src: require("../../assets/logo-car2.webp"),
          height: "40px",
          width: "120px",
          alt: "",
        },
        {
          src: require("../../assets/logo-car3.webp"),
          height: "40px",
          width: "120px",
          alt: "",
        },
        {
          src: require("../../assets/logo-car4.webp"),
          height: "40px",
          width: "120px",
          alt: "",
        },
        {
          src: require("../../assets/logo-car5.webp"),
          height: "40px",
          width: "120px",
          alt: "",
        },
        {
          src: require("../../assets/logo-car6.webp"),
          height: "40px",
          width: "120px",
          alt: "",
        },
        {
          src: require("../../assets/logo-car7.webp"),
          height: "40px",
          width: "120px",
          alt: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.logo-carousel {
  margin-top: 20px;
}

.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider .slide-track {
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(250px * 14); /* Double the original slides */
}

.slider .slide {
  height: 100px;
  width: 250px;
}
</style>
