<template>
  <div class="home">
    <MainMenu />
    <HomeHero />
    <LogoCarousel />
    <ServicesSection />
    <ProcessSection />
    <CaseStudies />
    <!-- <HappyCustomers /> -->
    <FaqsSection />
    <CallAction />
    <FooterMenu />
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import HomeHero from "@/components/Home/HomeHero.vue";
import LogoCarousel from "@/components/Home/LogoCarousel.vue";
import ServicesSection from "@/components/Home/ServicesSection.vue";
import ProcessSection from "@/components/Home/ProcessSection.vue";
import CaseStudies from "@/components/CaseStudies.vue";
import HappyCustomers from "@/components/HappyCustomers.vue";
import FaqsSection from "@/components/FaqsSection.vue";
import CallAction from "@/components/CallAction.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: "HomeView",
  metaInfo() {
    return {
      title: "Website Services - Home",
      meta: [
        {
          name: "description",
          content:
            "We offer professional website development services to help businesses establish a strong online presence.",
        },
        {
          name: "keywords",
          content:
            "web development, website services, professional web design, online presence, business websites",
        },
      ],
    };
  },
  components: {
    MainMenu,
    HomeHero,
    LogoCarousel,
    ServicesSection,
    ProcessSection,
    CaseStudies,
    HappyCustomers,
    FaqsSection,
    CallAction,
    FooterMenu,
  },
};
</script>
